<template>
  <edit-template :confirm-loading="confirmLoading" :data-loading="dataLoading" @confirm="submit">
    <el-form ref="form" :model="form" :rules="rules" label-width="140rem">
      <el-form-item label="账号" prop="username">
        <span>{{ form.username }}</span>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <span>{{ form.name }}</span>
      </el-form-item>
      <!--      <el-form-item prop="id_card" label="身份证号">-->
      <!--        <el-input v-model="form.id_card" maxlength="18" placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item prop="mailbox" label="邮箱">-->
      <!--        <el-input v-model="form.mailbox" placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item prop="nation" label="民族">-->
      <!--        <el-select-->
      <!--            v-model="form.nation"-->
      <!--            filterable-->
      <!--            remote-->
      <!--            reserve-keyword-->
      <!--            placeholder="请输入关键词"-->
      <!--            :remote-method="querySearch"-->
      <!--            :loading="loading">-->
      <!--          <el-option-->
      <!--              v-for="item in nationOptions"-->
      <!--              :key="item.id"-->
      <!--              :label="item.name"-->
      <!--              :value="item.id">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item v-if="roleInfo.is_admin" label="是否为集团员工" prop="is_group_member">
        <el-radio-group v-model="form.is_group_member" @input="getSchool($event,true)">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属校区" prop="school">
        <el-select v-model="form.school" :disabled="!roleInfo.is_admin || (roleInfo.is_admin && form.is_group_member == null)">
          <el-option v-for="item in schoolList" :key="item.id" :label="item.school_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否为部门负责人" prop="department_head">
        <el-radio v-model="form.department_head" :label="1">是</el-radio>
        <el-radio v-model="form.department_head" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="岗位类型" prop="role_type">
        <el-select v-model="form.role_type" @change="onRoleTypeChange">
          <el-option v-for="item in roleTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择岗位" prop="role_id">
        <el-select v-model="form.role_id" :disabled="!form.role_type" filterable>
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="教学学科" prop="subject">
        <el-cascader ref="subject" v-model="form.subject" :options="subjects"></el-cascader>
      </el-form-item>

    </el-form>
    <div v-show="historyData.length" class="record">
      <p style="margin-bottom: 40rem;font-weight: bold;">账号操作记录</p>
      <el-timeline style="padding-left: 40rem;">
        <el-timeline-item
            v-for="(item, index) in historyData"
            :key="index"
            hide-timestamp
            size="large"
            type="primary"
        >
          {{ item.change_detail }}
        </el-timeline-item>
      </el-timeline>
    </div>
  </edit-template>
</template>

<script>
import {getSubject} from './api';
import {system_history} from "@/api";

export default {
  _config: {"route": {"path": "edit", "meta": {"title": "编辑"}}},
  data() {
    return {
      historyData: [], // 历史数据
      loading: null,
      dataLoading: false,
      roleInfo: {},
      confirmLoading: false,
      form: {
        username: null, // 手机号
        name: null, // 姓名
        id_card: null,// 身份证号
        nation: null, // 民族
        nation_name: null, // 民族(选中数据)
        school: null, // 校区
        is_group_member: null, // 是否为集团员工
        department_head: null, // 是否为部门负责人
        role_id: null,
        role_type: null,
        subject: null
      },
      nationOptions: [],
      subjects: [], // 教学学科列表
      schoolList: [],
      // 岗位列表
      roleTypeList: [],
      // 岗位列表
      roleList: [],
      rules: {
        username: [{required: true, message: '请输入手机号', trigger: 'blur'}, {min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur"}],
        name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        id_card: [{pattern: /^((\d{17})|(\d{14}))[0-9X]$/, message: "请输入正确的身份证号", trigger: "blur"}],
        school: [{required: true, message: '请选择校区', trigger: 'change'}],
        nation: [{required: true, message: '请输入民族', trigger: 'change'}],
        is_group_member: [{required: true, message: '请选择是否为集团员工', trigger: 'change'}],
        department_head: [{required: true, message: '请选择是否为部门负责人', trigger: 'change'}],
        role_id: [{required: true, message: '请选择选择岗位', trigger: 'change'}],
        role_type: [{required: true, message: '请选择岗位类型', trigger: 'change'}],
        mailbox: [{required: true, message: '请输入邮箱地址', trigger: 'change'}],
      },
      keyword: '',
    };
  },
  mounted() {
    this.getData().finally(() => this.dataLoading = false);
  },
  methods: {
    // 判断已有数据是否存在于options中
    optionsHasValue(options, value, callback) {
      return options.filter(item => {
        let res = item.id === value;
        if (res && callback) callback(item);
        return res;
      }).length > 0;
    },
    // 限制手机号长度
    onChangeMobile(val) {
      if (val.length <= 11)
        this.form.username = val;
    },
    getSchool(is_group_member, isLoading = false) {
      if (isLoading) {
        this.dataLoading = true;
        this.form.school = null;
      }
      // 获取校区
      return this.$_axios2.get("api/permission/school-list", {
        logic: 1,
        params: {
          is_group_member
        }
      }).then(res => {
        this.schoolList = res.data;
        // 如果绑定值不存在于options中 , 认为该数据已被删除 , 则置空该项 , 需重新选择数据
        if (!this.optionsHasValue(res.data, this.form.school))
          this.form.school = null;
      }).finally(() => {
        if (isLoading) this.dataLoading = false;
      })
    },
    // 获取数据
    async getData() {
      this.dataLoading = true;
      // 获取角色类型
      await this.$utils.loginApi.getRole().then(res => {
        this.roleInfo = res.data;
      }).catch(err => {
        console.log(err.msg || err.message);
      })
      await system_history({tbl_id: this.$route.query.id, tbl_name: 'teacher_account'}).then(res => {
        this.historyData = res.list || []
      })


      // 获取账号数据
      await this.$_axios2.get("api/account/account-detail", {
        params: {
          id: this.$route.query.id
        },
        logic: 1
      }).then(res => {
        let {username, nation_name, name, id_card, nation, school, is_group_member, department_head, mailbox, role_type, current_role, subject} = res.data;
        this.nationOptions = [{id: nation, name: nation_name}]
        this.form = {
          username, // 手机号
          name, // 姓名
          id_card,// 身份证号
          nation, // 民族
          school: school ? parseInt(school) : school, // 校区
          is_group_member, // 是否为集团员工
          department_head, // 是否为部门负责人
          role_id: current_role, // 岗位
          role_type,
          mailbox,
          subject: (subject || []).map(item => item.toString())
        }

        this.getSchool(is_group_member);
        this.onRoleTypeChange(role_type, true);
      })
      // 获取岗位类型
      await this.$_axios2.get("api/permission/role-type", {logic: 1}).then(res => {
        this.roleTypeList = res.data;
        if (!this.optionsHasValue(res.data, this.form.role_type)) this.form.role_type = null;
      }).catch(err => {
        console.log(err.msg || err.message);
      })
      // 获取教学学科
      await getSubject().then(res => {
        this.subjects = JSON.parse(
            JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/subject_name/g, 'label').replace(/child/g, 'children'),
        );
        this.$nextTick(() => {
          let flag = false;
          let result = this.optionsHasValue(res.data.data, this.form.subject[0], item => {
            flag = this.optionsHasValue(item.child, this.form.subject[1]);
          })
          if (!flag || !result) this.form.subject = [];
        })
      });
    },
    // 岗位类型改变时触发
    onRoleTypeChange(e, init = false) {
      if (!init) {
        this.form.role_id = null;
      }
      this.dataLoading = true;
      this.$_axios2.get("api/permission/role-type-list", {
        params: {
          level: e
        },
        logic: 1
      }).then(res => {
        this.roleList = res.data;
        if (!this.optionsHasValue(res.data, this.form.role_id)) this.form.role_id = null;
      }).finally(() => this.dataLoading = false)
    },
    // 搜索民族
    querySearch(str) {
      this.loading = true;
      this.$_axios2.get("api/common/nation", {
        params: {
          keyword: str
        },
        logic: 1
      }).then(res => {
        this.nationOptions = res.data;
      }).finally(() => this.loading = false)
    },
    // 提交
    submit() {
      this.$refs.form.validate(res => {
        if (res) {
          this.confirmLoading = true;
          let params = Object.assign({}, this.form);
          params.id = this.$route.query.id;
          delete params.role_type;
          this.$_axios2.post("api/account/edit-account", params, {logic: 1}).then(res => {
            this.$message.success("修改成功");

            this.$router.back();
          }).finally(() => this.confirmLoading = false)
        }
      })
    },

  },

};
</script>

<style lang="scss" scoped>
.checkbox {
  min-width: 100rem;
  margin: 10rem;
}

::v-deep .el-input--small .el-input__inner {
  width: 300rem;
  height: 40rem;
  color: #999;
}

::v-deep .el-checkbox-group {
  max-height: 400rem !important;
  overflow: scroll;
}
</style>
